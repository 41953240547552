 body {
   margin: 0;
   font-family: 'Inter', sans-serif;
   -webkit-font-smoothing: antialiased;
   -moz-osx-font-smoothing: grayscale;
 }
:root {
  --primary-color:#6C5DD3; 
  --secondary-color: #6c757d;
  --text-color: #808191;
  --input-border:#dedede;
  --input-background:#f5f5f5;
  --input-color:#11142D;
  --input-white:#fff;
}
 code {
   font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
     monospace;
 }

a {
  color: #000;
  text-decoration: none;
}
 .error {
   color: red;
 }

 input.errorinput,
 select.my-select.errorinput,
 .my-select.errorinput .css-13cymwt-control {
   border-color: red;
   outline: none;
 }

 .myinput input:focus,
 .myinput input:focus-visible {
   border-color:   var(--primary-color);
   background: var(--input-white);
 }

 .myinput {
   margin-bottom: 10px;
 }

 .myinput input {
  width: 100%;
  height: 50px;
  padding:0 35px 0px 15px;
  border-radius: 12px;
  border: 1px solid var(--input-border);
  background: var(--input-background);  
  font-size: 12px;
  font-weight: 500;
  color: var(--input-color);
  transition: all .2s;
  outline: none;
  line-height: 16px;
  letter-spacing: 0.9px;
}
 .login-feild-wrap {
   position: relative;
 }
 .login-title h4 {
  font-size: 24px;
  line-height: 24px;
  letter-spacing: -0.5px;
  text-transform: capitalize;
  font-family: 'Poppins', sans-serif;
  margin-bottom: 35px;
}
 label.formlable {
   color:   var(--text-color);
   text-transform: uppercase;
   font-size: 10px;
   line-height: 1.6;
   letter-spacing: .9px;
   -webkit-transition: -webkit-transform .2s;
   transition: -webkit-transform .2s;
   -o-transition: transform .2s;
   transition: transform .2s;
   transition: transform .2s, -webkit-transform .2s;
   font-weight: 700;
   position: absolute;
   top: 14px;
   left: 25px;
 }

 .button-login button {
   height: 48px;
   padding: 0 24px;
   border-radius: 12px;
   font-family: "Inter", sans-serif;
   font-size: 14px;
   font-weight: 600;
   -webkit-transition: all .2s;
   -o-transition: all .2s;
   transition: all .2s;
   background-color:   var(--primary-color);
   color: var(--input-white);
   border: 0;
   width: 100%;
   line-height: 24px;
   margin-top: 15px;
 }

 .button-login button:hover {
   background-color:  var(--primary-color);
 }

 /************************************************************************************************************/
 .menu {
   width: 100%;
   height: 50px;
   align-items: center;
 }
 #menu~.navbarclass .icon .menu .logo-wrap {
  display: none;
}
 #menu:checked~.navbarclass .icon .menu .logo-wrap {
   display: block;
 }

 #menu:checked~.navbarclass .icon .menu.justify-content-between {
   justify-content: space-between !important;
 }

 #menu~.navbarclass .icon .menu.justify-content-between {
   justify-content: center !important;
 }

 #menu:checked~.navbarclass .icon .menu h5 {
   display: block;   
 }

 input#menu {
   display: none;
 }
 #menu:checked~.navbarclass .menu svg{
  transform: scale(1,1);
 }
.menu svg{
  transform: scale(-1,-1);
}
 #menu .icon .menu svg {
   transform: rotate(-180deg);
   margin-left: 16px;
 }

 #menu+.icon .menu h5 {
   display: none;
 }

 #menu~.navbarclass {
   width: 76px;
   z-index:11;
 }

 #menu:checked~.navbarclass {
   width: 256px;
 }

 #menu:checked .icon .menu svg {
   align-self: center;
   transition: width ease .12s;
   transform: rotate(0);
 }
 ul.sidemenu {
   padding: 0;
 }

 #menu:checked~.navbarclass .sidemenu li a {
   font-size: 14px;
   width: 100%;
   display: flex;
   font-weight: 500;
   padding: 0 40px;
 }

 .icon {
   width: 100%;
   padding: 20px 15px;
 }

 #menu:checked~nav .sidemenu li {
   padding: 0 20px;
 }

 #menu:checked~.navbarclass .sidemenu li svg {
   margin-right: 10px;
 }

 #menu:checked~.navbarclass .sidemenu li {
   padding: 0 0;
 }

 .sidemenu li svg {
   height: 24px;
   width: 24px;
   margin-right: 00px;
 }

 .sidemenu li {
   list-style-type: none;
   align-items: center;
   color:   var(--text-color);
   overflow: hidden;
   display: flex;
   margin-bottom: 5px;
   cursor: pointer;
 }

 .sidemenu li.active a,
 .sidemenu li.active a:hover {
   background-color: #e5e1ff;   
 }
/* 
 .sidemenu li.active a {
   color: var(--input-white)
 } */

.sidemenu li a:hover{
 background-color: #f5f5f5; 
}
 .image-preview-container {
   gap: 15px;
   flex-wrap: wrap;
 }

 .sidemenu li a {
   color: #53545e;
   text-decoration: none;
   font-size: 0;
   font-weight: 600;
   height: 45px;
   align-items: center;
   padding: 0 27px;
   display: flex;
   align-items: center;
    -webkit-transition: all .25s;
      -o-transition: all .25s;
      transition: all .25s;
 }
 label.icon h5 {
   font-size: 25px;
   font-family: 'Poppins', sans-serif;
   font-weight: 600;
   color:   var(--primary-color);
 }

 .navbarclass {
   overflow: hidden;
   width: 250px;
   position: fixed;
  -webkit-transition: all .25s;
    -o-transition: all .25s;
    transition: all .25s;
   z-index: 7;
   box-shadow: 0 2px 4px rgba(0, 0, 0, .1);
   border-right: 1px solid #E4E4E4;
   background: var(--input-white);
   height: 100vh;
 }

 .main-section,
 .navbar {
   padding: 20px;
   width: calc(100% - 75px);
   float: right;
  -webkit-transition: all .25s;
    -o-transition: all .25s;
    transition: all .25s;
 }

 .navbar {
   border-bottom: 1px solid #e4e4e4;
   padding: 5px 20px;
   position: fixed;
   background: #fff;
   right: 0;
   z-index: 9;
 }
 .main-section{
  margin-top: 65px;
 }
 #menu:checked~.main-section,
 #menu:checked~.navbar {
   width: calc(100% - 256px);
 }

 .mytable th {
   font-size: 16px;
   line-height: 1.38462;
   font-weight: 500;
   color: var(--input-color);
   padding: 10px 15px 10px 15px;
 }

 .checkbox__tick {
   position: relative;
   -webkit-box-flex: 0;
   -ms-flex: 0 0 20px;
   flex: 0 0 20px;
   width: 20px;
   height: 20px;
   border-radius: 4px;
   border: 2px solid #E4E4E4;
   -webkit-transition: all .25s;
   -o-transition: all .25s;
   transition: all .25s;
 }

 .checkbox__tick:before {
   content: "";
   position: absolute;
   top: 50%;
   left: 50%;
   -webkit-transform: translate(-50%, -50%);
   -ms-transform: translate(-50%, -50%);
   transform: translate(-50%, -50%);
   width: 10px;
   height: 9px;
   opacity: 0;
   background: url('../public/Assets/images/download.svg') no-repeat 50% 50% / 100% auto;
   -webkit-transition: opacity .25s;
   -o-transition: opacity .25s;
   transition: opacity .25s;
 }

 .checkbox__input {
   position: absolute;
   top: 0;
   left: 0;
   opacity: 0;
   z-index:8;
   height: 20px;
   width:20px;
 }

 .checkbox__input:checked+.checkbox__in .checkbox__tick:before {
   opacity: 1;
 }

 .checkbox {
   display: inline-block;
   position: relative;
   -webkit-user-select: none;
   -moz-user-select: none;
   -ms-user-select: none;
   user-select: none;
   cursor: pointer;
   -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
 }

 .checkbox__in {
   display: flex;
 }

 .checkbox__input:checked+.checkbox__in .checkbox__tick {
   background:   var(--primary-color);
   border-color:   var(--primary-color);
 }

 .checkbox:hover .checkbox__tick {
   border-color:   var(--primary-color);
 }

 table.mytable th {
   text-transform: capitalize;
 }
 table.mytable td {
   padding: 10px 15px 10px 15px;
   font-size: 14px;   
   color: #11142D;
   line-height: 100%;
   text-transform: capitalize;
   align-content: center;   
 }

 .showWrap {
   height: 40px;
   width: 40px;
   background: linear-gradient(66deg, #6c5dd3 15.4%, #a372f2 84.6%);
   border-radius: 10px;
   position: relative;
   color: var(--input-white);
   border: 0;
   box-shadow: 0px 1px 6px 0px #7D5CD570;
 }
 .showWrap.view   {
      color: #000;
      background: transparent;
      box-shadow: none;
      height: auto;

    }
.showWrap.view:hover {
          color:var(--primary-color);
          background:none;
        }
.showWrap.view svg{
          width: 18px;
          height: 18px;
        }
.showWrap.rmvpopbtn{
  height:44px;
  width: 44px;
}
 .showWrap:hover {
  background: linear-gradient(66deg, #292B2F 15.4%, #50555C 84.6%);
 }
.showWrap.rmvpopbtn svg{
  height: 16px;
  width:16px;
}
 .showWrap svg {
   height: 12px;
   width: 12px;
   align-self: center;
   top: 50%;
   position: absolute;
   transform: translate(-50%, -50%);
   left: 50%;
 }

 .showWrap.hide {
   background: #ced3f7;
 }

 .showWrap img {
   position: absolute;
   left: 0;
   top: 0;
   margin: auto;
   bottom: 0;
   right: 0;
   height: 30px;
   width: 30px;
 }
.title h6{
  margin-bottom: 0;
}
 .marginright {
   margin-right: 10px;
 }
 .marginleft {
   margin-left: 10px;
 }
 .mybtn {   
   color: var(--input-white);
   padding: 10px 15px;
   border: 0;
   border-radius: 10px;
   text-transform: capitalize;
  box-shadow: 0px 1px 8px 0px #7D5CD53D;
  background: linear-gradient(66deg, #6C5DD3 15.4%, #AF56D9 84.6%);
 }

 .mybtn:hover {
  background: linear-gradient(66deg, #292B2F 15.4%, #50555C 84.6%);
 }

 /********* Pagoination ***************/
 .pagination__item.active {
   background: linear-gradient(66deg, #6c5dd3 15.4%, #a372f2 84.6%);
   border-color:   var(--primary-color);
   color: var(--input-white);
 }
.pagination__item:hover{
  background-color: linear-gradient(66deg, #292B2F 15.4%, #50555C 84.6%);
}
 .modal.show .modal-dialog {
   transform: none;
   transform: translateY(-50%);
   top: 50%;
 }

 /*********** popup input *****************/
 .popup__input.search__input {
   border-color: #B2B3BD;
   margin: 0;
 }

 .search {
   position: relative;
   width: auto;
   min-width: 70%;
 }

 .popup__input,
 select.my-select {
   width: 100%;
   height: 45px;
   padding: 10px 20px;
   border-radius: 8px;
   background:transparent;   
   font-size: 14px;
   font-weight: 400;
   color: #11142D;
   -webkit-transition: all .2s;
   -o-transition: all .2s;
   transition: all .2s;
   outline: none;   
   margin-bottom: 10px;
   border:1px solid #dedede;
   outline: none;
   position: relative;
   z-index: 5;
 }

 textarea.popup__input {
   min-height: 200px;
 }

 .search svg {
   width: 20px;
   height: 20px;
   position: absolute;
   top: 50%;
   right: 20px;
   transform: translateY(-50%);
 }

 .popup__input:focus,
 select.my-select:focus {
   border-color:   var(--primary-color);
 }

 select.my-select option {
   margin-top: 10px;
   padding: 20px;
 }

 .popup__label {
   margin-bottom: 16px;
   font-size: 12px;
   line-height: 1.33333;
   font-weight: 500;
   color:   var(--text-color);
   display: block;
 }

 /* #e4e4e4 */
 /***************** Switch css ***************/
 /* The switch - the box around the slider */
 .switch {
   position: relative;
   display: inline-block;
   width: 36px;
   height: 18px;
 }

 /* Hide default HTML checkbox */
 .switch input {
   opacity: 0;
   width: 0;
   height: 0;
 }

 /* The slider */
 .slider {
   position: absolute;
   cursor: pointer;
   top: 0;
   left: 0;
   right: 0;
   bottom: 0;
   background-color: #ccc;
   -webkit-transition: 0.4s;
   transition: 0.4s;
 }

 .slider:before {
   position: absolute;
   content: "";
   height: 12px;
   width: 12px;
   left: 4px;
   top: 0;
   background-color: white;
   /* transition: .4s; */
   bottom: 0;
   margin: auto;
 }

 input:checked+.slider {
   background: linear-gradient(66deg, #6C5DD3 15.4%, #AF56D9 84.6%);
 }

 input:focus+.slider {
   box-shadow: 0 0 1px   var(--primary-color);
 }

 input:checked+.slider:before {
   -webkit-transform: translateX(23px);
   -ms-transform: translateX(23px);
   transform: translateX(16px);
 }

 /* Rounded sliders */
 .slider.round {
   border-radius: 20px;
 }

 .slider.round:before {
   border-radius: 50%;
 }

 /*********** Pagination /****************/

 .pagination__item,
 .pagination__next {
   display: -webkit-box;
   display: -ms-flexbox;
   display: flex;
   -webkit-box-pack: center;
   -ms-flex-pack: center;
   justify-content: center;
   -webkit-box-align: center;
   -ms-flex-align: center;
   align-items: center;
   -ms-flex-negative: 0;
   flex-shrink: 0;
   height: 40px;
   margin: 8px 0 0 8px;
   border-radius: 12px;
   border: 1px solid #E4E4E4;
   font-size: 14px;
   font-weight: 700;
   color: #11142D;
   -webkit-transition: all .2s;
   -o-transition: all .2s;
   transition: all .2s;
   width: 40px;
 }

 /* .pagination__next {
   width: auto;
   padding: 0 25px;
 } */

 .pagination__item:hover,
 .pagination__next:hover {
   border-color:   var(--primary-color);
 }

 button.pagination__next:disabled,
 button.pagination__next[disabled] {
   border-color: #E4E4E4;
   color: #e4e4e4
 }
button.pagination__next:disabled svg path {
  stroke: #e4e4e4;
  fill:#e4e4e4;
}

 button.pagination__next:disabled svg {
   color: #e4e4e4 !important
 }

 /************* dropdown arrow *****************/
 .shownumber select {      
   padding-top: 0;
   margin-bottom: 0;
   padding-bottom: 0;   
   z-index: 5;
   background-color: transparent;
 }
.shownumber{
    margin-left: 10px;
    margin-top: -10px;
  }
 .imageWrap {
   width: 40px;
   height: 40px;
   position: relative;   
   border-radius: 50%;
   overflow: hidden;
   box-shadow: 3px 3px 2px #ced3f7
 }

 .imageWrap img,
 .imageWrap video {
   width: 100%;   
   object-fit: cover;
   height: 100%;
 }
 .modal-dialog {
   max-width: 1000px;
   max-height: 800px;
 }

 .profileform {
   display: flex;
   flex-wrap: wrap;
 }

 .btnrmvwrap {
   position: relative;
 }

 .profileform>div {
   flex: 32%;
   margin-right: 2%;   
 }
.profileform>div.full {
  flex: 100%;
  margin: 0;
}
.profileform>div:nth-child(3),
.profileform>div:nth-child(6)
{
  margin-right: 0;
}
.profileform>div:nth-child(7),
.profileform>div:nth-child(8),
.profileform>div:nth-child(9),
.profileform>div:nth-child(10) {
  flex: 49%;
}

.profileform>div:nth-child(8),
.profileform>div:nth-child(10)
{
margin-right: 0;
}
 .profileform .popup__label {
   margin-bottom: 5px;
 }

 .css-tj5bde-Svg {
   color: var(--input-color);
   height: 17px;
   width: 17px;
 }

.remove-btn {
  height: 24px;
  width: 24px;
  z-index: 0;
  position: absolute;
  right: -7px;
  top: 0;
  border: 1px solid var(--primary-color);
  border-radius: 6px;
  margin-left: 0;
  background: var(--primary-color);
  color: white;
  LINE-HEIGHT: 24px;
}

 .remove-btn:hover {
   background-color: var(--input-white);
   color:   var(--primary-color)
 }

 .remove-btn svg {
   height: 20px;
   width: 20px;
 }

.remove-btn svg {
  height: 13px;
  width: 13px;
  position: absolute;
  left: 0;
  top: 0;
  margin: auto;
  right: 0;
  bottom: 0;
}

 .logout-btn {
   position: absolute;
   top: 93%;
   left: 12px;
 }

 .svgwrap {
   width: 15px;
   height: 15px;
   position: absolute;
   right: 10px;
   top: 8px;
   transform: translateY(50%);
   line-height: 100%;
   color: red;
 }

 .svgwrap.green {
   color: green;
 }

 button.forget-btn {
   background-color: transparent;
   border: 0;
   font-weight: 500;
   font-size: 14px;
 }

 .switch.form .slider:before {
   top: 1.8px
 }
 .eye {
  height: 20px;
  width: 20px;
  position: absolute;
  top: 12px;
  right: 12px;  
}
.eye svg{
  color:  var(--text-color)
}
.changebtn{
  background: transparent;
  color: var(--input-color);
  border: 0;
  padding: 0;
}
.container-login{
  max-width: 1650px;
  margin: 0 auto;
}
.logincontainerwrap{  
  background: #ebebeb;
  border-radius: 16px;
}
.navbar .dropdown-toggle::after{
  display: none;
}
.navbar .show button {
  border-radius: 50%;
  padding: 0;
  align-items: center;
  font-size: 14px;
  font-weight: 500;
  color:  #53545E;
}
.navbar .btn{
  padding: 0;
  border-radius: 50%;
}
.navbar .show button:hover {
  color:  var(--primary-color);
  background-color: transparent;
}
.navbar .show button:hover svg path{
  stroke:var(--primary-color);
}
.navbar .show .dropdown-menu.show{
  margin-top: 10px;
  border-color:#e4e4e4;
  background-color: #fff;
}
.navbar .show .btn-check:checked+.btn,
.navbar .show .btn.active,
.navbar .show .btn.show,
.navbar .show .btn:first-child:active,
.navbar .show :not(.btn-check)+.btn:active{
  border-color:transparent;
}
.navbar .btn-check:focus+.btn,
.navbar .btn:focus{
  box-shadow: none;
}
.svgwarp.drop{
  margin-right: 10px;
}
.navbar a.dropdown-item:hover{
  background: none;
}
.menu-title p{
    padding-left: 40px;
    font-size: 0px;
    font-weight: 400;
    color:   var(--text-color);
    font-family: 'Inter', sans-serif;
}
#menu:checked~.navbarclass .menu-title p{
    font-size: 12px;
 }
    .imageWrapcustum,
    .imageWrap.profile {
       position: relative;
       height: 86px;
       width: 86px;
       border: 1px solid #6C5DD3;
       text-align: center;
       color: #6C5DD3;
       border-radius:8px;
       margin-top: 10px;
       box-shadow: none;
     }
      .imageWrapcustum input[type="file"] {
                   -webkit-appearance: none;
                   position: absolute;
                   top: 0;
                   left: 0;                   
                   height: 86px;
                   width: 86px;
                   opacity: 0;
                 }
     .imageWrapcustum:before{    
      content: '+';
       position: absolute;
       top: 0;
       left: 0;
       height:100%;
       width: 100%;              
       font-size: 30px;
       font-weight: 300;
       line-height: 86px;
       margin: auto;
     }
.my-select .css-1p3m7a8-multiValue {
  background-color: #f3f3f3;
  border-radius: 6px;
  padding: 4px 6px;
  font-size: 14px;
  font-weight: 500;  
}
.my-select .css-t3ipsp-control{
  border:1px solid var(--primary-color);
  box-shadow: none;
  border-radius: 8px;
}
.my-select .css-d7l1ni-option{
  background-color: #ebe9fe;
}
.my-select .css-qbdosj-Input{
  margin: 5px;
}
.my-select .css-t3ipsp-control:hover {
  border-color: var(--primary-color);
}
.my-select{
 -moz-appearance: none;
 -webkit-appearance: none;
 appearance: none;
}
.custon:after,
button.my-select:after {
  content: url('./images/Image.svg');
  position: absolute;
  height: 0;
  background: #fff;
  width: 0;
  right: 25px;
  line-height: 49px;
  top: 0;
  z-index: 1;
}
.mybtn.black{
  background-color: var(--input-color);
}
.mybtn.black:hover {
background-color: var(--primary-color);
}
.logo-content h4{
  font-size: 32px;
  font-weight: 600;
  font-family:'Poppins', sans-serif;
  margin-bottom: 0;
}
.logo-wrap1{
  height: 52px;
  width:52px;
  position: relative;
}
.logo-wrap1 img{
  position:absolute;
  height: 100%;
  width:100%;
  top:0;
  bottom: 0;
  right:0;
  left:0;
  margin: auto;
}
.statussubadmin{
  height: 15px;
  width: 15px;
    background: red;
    border-radius: 50%;
    cursor: pointer;
}
.statussubadmin.green {
  background-color: green;
}
.showWrap.auto {
  width: 100%;
  padding: 0 15px;
  background: white;
  color: #000;
  border-radius: 0;
  box-shadow: none;
}
.showWrap.auto svg {
  display: inline-block;
  position: relative;
  left: auto;
  top: -2px;
  transform: none;
  margin-right: 10px;
  height: 15px;
  width: 15px;
}
.my-dropdown .dropdown-menu{
  width:auto;
}
.dotdisplay.showw{
  opacity: 1;
}
.dotdisplay{
  opacity: 0;
}
.btn-primary.my-dropdown{
  background-color: var(--input-white);
  color:var(--input-color);
  border:0;
  box-shadow: none;
}
.btn-primary.my-dropdown svg{
  height: 20px;
}
.btn-primary.my-dropdown:active,
.show>.btn-primary.my-dropdown.dropdown-toggle:focus{
  background-color: transparent;
  color: var(--input-color);
  box-shadow: none;
}
.my-dropdown.dropdown-toggle::after{
  display: none;
}
.fulllengthclass{
  width: 100%;
}
.mycheckbox.borderg{
      border: 1px solid green;
}
 .categoryWrap{
   border-bottom: 1px solid #808191;
   margin: 10px 0;
   padding-bottom: 10px;
 }
 .categoryWrap .d-flex div{
  margin-right: 10px;
  align-items: center;
 }
button.subbtn{
    color: var(--primary-color);
    line-height: 100%;
    height: 30px;
    border: 0;
    background: #fff;
    margin-left: 15px;
  }
button.subbtn svg path{
  stroke: var(--primary-color);
}
.loader-1 {
  width: 25px;
  height: 25px;
  border: 3px solid #FFF;
  border-bottom-color: #AF56D9;
  border-radius: 50%;  
  -webkit-animation: rotation 1s linear infinite;
  animation: rotation 1s linear infinite;
  display: none;
}
.imageWrap.video {
  width: 100px;
  height: 100px;  
}
.loader-1.show {
  display: inline-block;
}
    @keyframes rotation {
      0% {
        transform: rotate(0deg)
      }
  
      100% {
        transform: rotate(360deg)
      }
    }  
  
.alert-success.fixed-top.rr{
  left :auto;
  top:15px;
  right:10px;
}
svg.pass{
  width: 20px;
  height: 20px;
}                  
.sitesWrap {
  border: 1px solid var(--primary-color);
  padding: 10px;
  border-radius: 10px;
  margin-bottom: 15px;
}
.siteCateIcon{
  position: relative;
  height:40px;
  width:40px;
  border:1px solid transparent;
  border-radius: 10px;
  overflow: hidden;
}
.siteCateIcon img{
  position: absolute;
  max-width: 100%;
  top:50%;
  left:50%;
  transform: translate(-50%,-50%);
}
.sitecategorywrap {  
  padding: 10px;
  margin-right: 10px;
  border-radius: 10px;
  background: #e5e1ff;
  position: relative;
  min-width: 120px;
  margin-bottom: 20px;
}
.sitecategorywrap.bg {
  border:1px solid var(--primary-color);
background-color: #f5f5f5;
}
.iconWrap {
  height: 20px;
  width: 20px;
  background: #000;
  position: absolute;
  border-radius: 5px;
  top: -10px;
  right: 5px;
  color:#fff;
  text-align: center;
  cursor:pointer ;
}
.iconWrap:hover{
  background-color:var(--primary-color);
}
.svgWrap{
  height:25px;
  width:25px;
  position: relative;
}
.iconWrap svg ,
.svgWrap svg{
  height: 12px;
  width: 12px;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
}
.iconWrap.edit{
  right: 55px;
}

.iconWrap.view {
  right: 30px;
}
.svgWrap svg {
  height: 25px;
  width: 25px;
}

.popup__input.color{
    width: 50px;
    padding: 10px;
  }

.sitecategorywrap.bg.Wallpaper {
  min-width: auto;
  padding: 0;
  width: 100px;
  height: 100px;
}
.siteCateIcon.Wallpaper {
  width: 100%;
  height: 100%;
  MARGIN: 0;
}
.accordion-button:not(.collapsed) {
  color: #000;
  background-color: #e5e1ff;  
  font-weight: 500;
  box-shadow: none;
}
.accordion-button:not(.collapsed)::after,
.accordion-button::after{
  background-image: url('./images/dropdown-arrow.svg');
}
.accordion-button{
  font-weight: 500;
}
.sitecategorywrap h6{
  margin: 0;
}
.iconWrap.view svg path{
  stroke: #fff;
  stroke-width: 3px;
}
.sitecategorywrap.d-block{
  min-width: auto;
  width: 100%;
  margin-right: 0;
}
.spandt{
  margin-top: -3px;
}
.svgwarp.drop svg path,
.svgwarp svg path{
  stroke: #53545e;
}
.highlight {
  background-color: yellow;
}



.multi-select-dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-toggle {
  background-color: #3498db;
  color: white;
  padding: 10px;
  border: none;
  cursor: pointer;
  
}
.dropdown-menu.true{
  display: block;
}
.dropdown-menu {
  position: absolute;
  background-color: white;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2);
  z-index: 1;
  width: 250px;
  max-height: 200px;
  overflow-y: auto;
  overflow-x: hidden;
  right: 0;
}

.dropdown-item {
  padding: 8px 16px;
  cursor: pointer;
}

.dropdown-item:hover {
  background-color: #f1f1f1;
}
button.my-select{
    background: #fff;
    border: 1px solid var(--primary-color);
    padding: 10px 30px 10px 10px;
    border-radius: 10px;
  }
.user-image {
  height: 200px;
  width: 200px;
  border: 2px solid #eee;
  border-radius: 50%;
  position: relative;
  overflow: hidden;  
}
.user-image img,
.imageTable img{
    width: 100%;
    height: 100%;    
    object-fit: cover;
  }
.user-content p,
.contact-details p{
  margin-bottom: 0;
}
.contactwrap{
  color: #666;
}
.contactwrap .showWrap.view { 
  color: #666;
  width: 14px;
}
.contacts{
  width: 32%;
  margin-right: 2%;
  float: left;
  margin-bottom: 20px;
  background-color: #fff;
  border: 1px solid #f5f5f5;
  border-radius: 12px;
  padding: 10px;
}
.contacts:nth-child(3n+0) {
  margin-right: 0;
}
.contacts:nth-child(3n+1) {
  clear: both;
}
.hashtagwrap a{
  text-decoration: none;
}
.imageTable {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  overflow: hidden;
  position: relative;
}
a.arwleft {
  display: inline-block;
  cursor: pointer;
  height: 20px;
  width: 20px;
  color: #000;
}
.divider {
  border-bottom: 1px solid #3c3c3c;
  margin: 15px 0;
}
.profile-info-sec {
  border: 1px solid #eee;
  border-radius: 8px;
  padding: 14px 16px;
  background: #f5f5f5;
}
.contactdetails h6,
.contactdetails p,
.profile-info-sec h6,
.profile-info-sec p{
  margin-bottom: 0;
}
button.arwleft{
    border: 0;
    background: #fff;    
    cursor: pointer;
  }
.title h4{
  margin-bottom: 0;
}
.leftarw svg{
  rotate: 180deg;
}
div.appUpdateAppDialogStatus,
div.appUpdateAppForceDialogStatus{
    width: 50%;
    float: left;
    display: flex;
    gap: 20px;    
  }
div.appRedirectOtherAppStatus{
    position: absolute;
    right: 0;
    top: 72px;
  }
  form.settingform{
    position: relative;
  }
th.mobile_numbers,th.mobile_num{
  width: 200px;
}
th.notes,th.createdAt,th.state,th.pin_code{
  width:150px;
}
th.email{
  width: 200px;
}
th.flag,th.image{
  width: 80px;
}
th.first_name,th.hashtag_id{
  width: 200px;
}
th.name{
  width: 400px;
}
th.hash_tags,th.user_id{
  width: 300px;
}
th.city,th.archived_type,th.counter{
  width: 100px;
}
th.country_code {
  width: 140px;
}
th.status {
  width: 70px;
}
th.dial_code,th.code {
  width: 150px;
}
td.align-middle.left-column.counter,td.country_code,td.dial_code,td.code,th.dial_code,th.code{
  text-align: center;
}
.contactwrap:hover .showWrap.view{
  color:#0d6efd;
}
th.user.counter,th.contact.counter{
  width:190px;
  text-align: center;
}
 .sort-icon {
   display: inline-block;
   width: 0;
   height: 0;
   margin-left: 5px;
   border-left: 5px solid transparent;
   border-right: 5px solid transparent;
 }

 .triangle-down::after {
   content: "";
   display: inline-block;
   vertical-align: middle;
   border-left: 5px solid transparent;
   border-right: 5px solid transparent;
   border-top: 5px solid black;
   /* Adjust color as needed */
 }

 .triangle-up::after {
   content: "";
   display: inline-block;
   vertical-align: middle;
   border-left: 5px solid transparent;
   border-right: 5px solid transparent;
   border-bottom: 5px solid black;
   /* Adjust color as needed */
 }
.seetingtools {
  width: calc(25% - 18px);
  background: #E5E1FF;  
  border-radius: 8px; 
  font-size: 20px;
  font-weight: 500;
  padding: 10px 15px;
}
th.appRedirectOtherAppStatus,
th.appUpdateAppDialogStatus{
  width: 150px;
}
th.appUpdateAppForceDialogStatus{
  width: 135px;
}
th.appVersionCode {
  width: 130px;
}
th.createdAt,
th.updatedAt {
  width: 185px;
}
th.appNewPackageName {
  width: 550px;
}
 @media (min-width: 600px) and (max-width: 1024px) {
  .container-login {
      max-width: 700px;
    }
        .logo-content h4 {          
          font-size: 23px;
        }
         .loginformWrap {
           width: 40% !important;
         }
        .loginformWrap.w-50 {
          width: 100% !important;
        }
         .logincontainerwrap {
           width: 55% !important;
         }
   .userr {
     display: block !important;
   }

   .user-head,
   .userr .w-75,
   .userr .user-info {
     width: 100% !important;
     min-width: 400px;
   }

   .userr .w-75 {
     justify-content: flex-start !important;
   }
.seetingtools {
  width: 100%;
}
   .table-responsive-sm {
     width: 100%;
     overflow-x: scroll;
   }

   .data_table.User.table,.mytable.users.table {
     min-width: 1500px;
   }
.mytable.Country.table,
.mytable.Hashtags.table,.userdetails.table{
  min-width: 800px;
}
.mytable.Contacts.table{
  min-width: 1000px;
}
.mytable.Setting.table{
min-width: 1500px;
}
   .search.marginright.w-50 {
     width: 70%;
   }

   .margin-flex {
     width: 100%;
   }

   .userr .user-info:first-child {
     margin-bottom: 15px;
   }
     .user-image {
       height: 150px;
       width: 150px;
     }
 }